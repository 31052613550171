export function buscarPoligonos(pagina = 1, limite = "15") {
  return {
    type: "@mosaico/BUSCAR_POLIGONOS",
    payload: { pagina, limite }
  };
}

export function adicionarDados(
  poligonos,
  atual,
  ultima,
  atualizado_em,
  conflitos,
  total,
  busca = false,
  zoom = 11,
  incompleto = false
) {
  return {
    type: "@mosaico/ATUALIZAR_POLIGONOS",
    payload: {
      poligonos,
      busca,
      atual,
      ultima,
      zoom,
      incompleto,
      atualizado_em,
      conflitos,
      total
    }
  };
}

export function buscarPlanta(id, documentos = false) {
  return {
    type: "@mosaico/BUSCAR_PLANTA",
    payload: { id, documentos }
  };
}

export function adicionarPlanta(planta, centro) {
  return {
    type: "@mosaico/ADICIONAR_PLANTA",
    payload: { planta, centro }
  };
}

export function limparCentro() {
  return {
    type: "@mosaico/LIMPAR_CENTRO"
  };
}

export function filtrarMosaicos(termo) {
  return {
    type: "@mosaico/FILTRAR_MOSAICOS",
    payload: { termo }
  };
}

export function debounceFiltro(termo) {
  return {
    type: "@mosaico/DEBOUNCE_FILTRAR_MOSAICOS",
    payload: { termo }
  };
}

export function limparBuscar(termo, ocultar = false) {
  return {
    type: "@mosaico/LIMPAR_BUSCA",
    payload: { termo, ocultar }
  };
}

export function buscarDocumentos(matricula, abrir_aba = false) {
  return {
    type: "@mosaico/BUSCAR_DOCUMENTOS",
    payload: { matricula, abrir_aba }
  };
}

export function adiconarDocumentos(documentos) {
  return {
    type: "@mosaico/ADICIONAR_DOCUMENTOS",
    payload: { documentos }
  };
}

export function limparDocumentos() {
  return {
    type: "@mosaico/LIMPAR_DOCUMENTOS"
  };
}

export function abrirArquivo(id) {
  return {
    type: "@mosaico/ABRIR_DOCUMENTO",
    payload: { id }
  };
}

export function atualizarImovel(
  id,
  matricula_atual,
  nome,
  detentor,
  cpfcnpj,
  sncr,
  area,
  matricula_ordem,
  categoria_imovel,
  coordenadas = [], 
  id_arquivo_matricula = null,
  id_arquivo_kml = null,
  rua = false,
  numero = false,
  logradouro = false,
  bairro = false,
  complemento = false,
  data_de_abertura = false,

) {
  return {
    type: "@mosaico/ATUALIZAR_IMOVEL",
    payload: {
      id,
      matricula_atual,
      nome,
      detentor,
      cpfcnpj,
      sncr,
      area,
      matricula_ordem,
      coordenadas,
      id_arquivo_matricula,
      id_arquivo_kml,
      categoria_imovel,
      rua,
      numero,
      logradouro,
      bairro,
      complemento,
      data_de_abertura
    }
  };
}

export function adicionarNome(nome) {
  return {
    type: "@mosaico/ADICIONAR_PESSOA_NOME",
    payload: { nome }
  };
}

export function buscarPorSncr(termo) {
  return {
    type: "@mosaico/BUSCAR_SNCR",
    payload: { termo }
  };
}

export function addImovelBuscado(imovel) {
  return {
    type: "@mosaico/ADD_IMOVEL_BUSCADO",
    payload: { imovel }
  };
}

export function mesclarImoveis(imovel_id, imovel_mesclar_id) {
  return {
    type: "@mosaico/MESCLAR_IMOVEL",
    payload: { imovel_id, imovel_mesclar_id }
  };
}

export function atualizarEstadoDaListagem(busca_coord, busca_matricula) {
  return {
    type: "@mosaico/ESTADO_LISTAGEM",
    payload: { busca_coord, busca_matricula }
  };
}

export function filtroDeRetificados(tipo) {
  return {
    type: "@mosaico/RETIFICADOS",
    payload: { tipo }
  };
}

export function filtroDaLista(tipo) {
  return {
    type: "@mosaico/LISTAGEM",
    payload: { tipo }
  };
}

export function statusLoading(loading = false) {
  return {
    type: "@mosaico/STATUS_LOADING",
    payload: { loading }
  };
}

export function statusLoadingFiltro(loading = false) {
  return {
    type: "@mosaico/STATUS_LOADING_FILTRO",
    payload: { loading }
  };
}

export function statusLoadingMapa(loading = false) {
  return {
    type: "@mosaico/STATUS_LOADING_MAPA",
    payload: { loading }
  };
}

export function adicionarDetentor(detentores) {
  return {
    type: "@mosaico/ADD_DETENTOR",
    payload: { detentores }
  };
}

export function addMarcosImovel(posicao) {
  return {
    type: "@mosaico/ADD_MARCO",
    payload: { posicao }
  };
}

export function limparMarcosImovel() {
  return {
    type: "@mosaico/LIMPAR_MARCO"
  };
}

export function enviarArquivoKmlTesteSobreposicaoEMunicipios(
  id_imovel,
  kml,
  matricula_atual
) {
  return {
    type: "@mosaico/VALIDAR_KML_SOBREPOSICAO",
    payload: { id_imovel, kml, matricula_atual }
  };
}

export function imovelTemSobreposicao(novo_imovel_tem_sobreposicao) {
  return {
    type: "@mosaico/IMOVEL_SOBREPOSICAO",
    payload: { novo_imovel_tem_sobreposicao }
  };
}

export function buscarSituacao(id, qr_code) {
  return {
    type: "@mosaico/BUSCAR_SITUACAO",
    payload: { id, qr_code }
  };
}

export function novaSituacao() {
  return {
    type: "@mosaico/NOVA_SITUACAO_IMOVEL"
  };
}

export function removerImovel(id) {
  return {
    type: "@mosaico/REMOVER_IMOVEL",
    payload: { id }
  };
}

export function gerarMemorial(imovel_id) {
  return {
    type: "@mosaico/GERAR_MEMORIAL",
    payload: {
      imovel_id
    }
  };
}

export function addImoveisFiltrados(imoveis) {
  return {
    type: "@mosaico/ADD_IMOVEIS_FILTRADOS",
    payload: { imoveis }
  };
}