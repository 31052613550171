import { combineReducers } from "redux";
import popup from "@nato-digital/nato-components/src/store/popup/reducer";
import matricula from "@nato-digital/nato-components/src/store/matricula/reducer";
import auth from "./auth/reducer";
import mosaico from "./mosaico/reducer";
import toast from "./toast/reducer";
import municipio from "./municipio/reducer";
import dashboard from "./dashboard/reducer";
import acervo from "./acervo/reducer";
import comparar from "./comparar/reducer";

export default combineReducers({
  auth,
  mosaico,
  toast,
  municipio,
  dashboard,
  popup,
  acervo,
  matricula,
  comparar,
});
