import { all, call, takeLatest, put, delay } from "redux-saga/effects";

import {
  showPopUp,
  removePopUp,
  estadoInput
} from "@nato-digital/nato-components/build/store/popup/actions";
import apiVirtualCart from "~/services/apiVirtualCart";
import { store } from "~/store";

import { adicionarNome } from "../mosaico/actions";
import { adicionarFoto } from "~/store/modules/auth/actions";
import { adicionarDetentor } from "~/store/modules/mosaico/actions";
import { addToast } from "~/store/modules/toast/actions";
import { vincularDetentor as vincularDetentorAction } from "./actions";
import { validarCpf, validarCnpj } from "~/utils/validarCpfCnpj";

export function* buscarFoto({ payload }) {
  let { foto } = payload;

  if (foto) {
    const responseVirtual = yield call(
      apiVirtualCart,
      "get",
      `arquivos/${foto}`
    );

    let { status } = responseVirtual;

    switch (status) {
      case 200:
        yield put(adicionarFoto(true));
        break;

      default:
        console.log("Mosaico: Erro em encontrar foto");
    }
  }
}

export function* buscarPessoaCV({ payload }) {
  yield delay(1000);
  const { cpfcnpj } = payload;
  const response = yield call(
    apiVirtualCart,
    "get",
    `/gerencia/pessoa/existe/${cpfcnpj}`
  );

  const { data, status } = response;

  switch (status) {
    case 200:
      const { nome, razao_social } = data;
      yield put(estadoInput(true, "success"));
      yield put(adicionarNome(nome || razao_social));
      break;

    case 404:
      yield put(showPopUp(cpfcnpj));
      yield put(estadoInput(true, "danger"));
      break;
    case 422:
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
}

export function* vincularDetentor({ payload }) {
  let { cpfcnpj, detentor } = payload;

  const { planta } = store.getState().mosaico;

  yield put(
    adicionarDetentor([...planta.detentores, { cpfcnpj, nome: detentor }])
  );
  yield put(adicionarNome(undefined));
  yield put(
    addToast({
      titulo: "Proprietário adicionado com sucesso.",
      tipo: "success",
      duracao: 10000
    })
  );
}

export function* criarPessoaSimples({ payload }) {
  const { nome, cpfcnpj } = payload;

  let response;
  if (cpfcnpj.length === 11) {
    if (!validarCpf(cpfcnpj)) {
      yield put(
        addToast({
          titulo: "Este CPF não é válido.",
          tipo: "warning",
          duracao: 10000
        })
      );
      return false;
    }
    response = yield call(apiVirtualCart, "post", `/gerencia/pessoa/`, {
      cpf_cnpj: cpfcnpj,
      tipo: "pessoa_fisica",
      cpf: cpfcnpj,
      nome
    });
  } else {
    if (!validarCnpj(cpfcnpj)) {
      yield put(
        addToast({
          titulo: "Este CNPJ não é válido.",
          tipo: "warning",
          duracao: 10000
        })
      );
      return false;
    }
    response = yield call(apiVirtualCart, "post", `/gerencia/pessoa/`, {
      cpf_cnpj: cpfcnpj,
      tipo: "pessoa_juridica",
      cnpj: cpfcnpj,
      razao_social: nome
    });
  }

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(adicionarNome(nome));
      yield put(
        addToast({
          titulo: "Usuário criado com sucesso",
          tipo: "success",
          duracao: 10000
        })
      );
      yield put(estadoInput(true, "success"));

      const regex = /(\d)+/gm;
      const search = window.location.pathname.match(regex);
      const id = search[0];
      yield put(vincularDetentorAction(id, cpfcnpj, nome));
      break;

    case 404:
    case 422:
      yield put(estadoInput(false));
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning"
        })
      );
      break;

    default:
      yield put(estadoInput(false));
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
  yield put(removePopUp(nome, false));
}

export function* desvincularDetentor({ payload }) {
  let { detentor_cpfcnpj } = payload;

  const { planta } = store.getState().mosaico;

  const detentoresFiltrados = planta.detentores.filter(
    detentor => detentor_cpfcnpj !== detentor.cpfcnpj
  );
  yield put(adicionarDetentor(detentoresFiltrados));

  yield put(
    addToast({
      titulo: "Remoção de proprietário realizada com sucesso.",
      tipo: "success",
      duracao: 10000
    })
  );
}

export function* removePopReducer({ payload }) {
  const { nome } = payload;
  if (nome) {
    yield put(adicionarNome(nome));
  } else {
    yield put(adicionarNome(undefined));
  }
}

export default all([
  takeLatest("@usuario/BUSCAR_FOTO", buscarFoto),
  takeLatest("@usuario/VINCULAR_DETENTOR", vincularDetentor),
  takeLatest("@usuario/DESVINCULAR_DETENTOR", desvincularDetentor),
  takeLatest("@CV_PopUp/BUSCAR_PESSOA", buscarPessoaCV),
  takeLatest("@CV_PopUp/CRIAR_USUARIO_SIMPLES", criarPessoaSimples),
  takeLatest("@CV_PopUp/REMOVE_POPUP", removePopReducer)
]);
