import Coordinate from "coordinates-converter";

function adicionarLateLong(termo) {
  try {
    const termo_lista = termo.split(/[.,]/);
    let primeiro = false;
    let coord_lat = termo_lista[1].split("").map(i => {
      if (i === " " && !primeiro) {
        primeiro = true;
        return `${i} S `;
      }
      return i;
    });
    termo_lista[1] = `,${coord_lat.join("")}`;
    termo_lista[2] = `,${termo_lista[2]} W`;
    return termo_lista.join("");
  } catch (error) {
    return null;
  }
}

function validarTermoEhCoordenada(termo) {
  let regex = /([-+]?)([\d]{1,})(((\.)(\d{2,})(\s*,\s*| \s*)))(([-+]?)([\d]{1,})((\.)(\d{2,}))+)/gm;
  let coordenadaValida = termo.match(regex);

  let isnum = /^\d+$/.test(termo);
  if (isnum) {
    return false;
  }

  try {
    if (!coordenadaValida) {
      regex = /([-+]?)([0-8]?\d(°?\s{0,})[0-5]?\d('?\s{0,})[0-5]?\d(.\d{1,6})?"?\s{0,}|90(°?\s{0,})0?0('?\s{0,})0?0"?)\s{0,}[NnSs]?\s{0,}([0-]?[0-7]?\d(°?\s{0,})[0-5]?\d('?\s{0,})[0-5]?\d(.\d{1,6})?"?\s{0,}|180(°?\s{0,})0?0('?\s{0,})0?0"?)\s{0,}[EeOoWw]?|\s{0,}([-+]?)([0-8]?\d(°?\s{0,})[0-5]?\d('?\s{0,})[0-5]?\d(.\d{1,6})?"?\s{0,}|90(°?\s{0,})0?0('?\s{0,})0?0"?)\s{0,}[EeOoWw]?\s{0,}([0-]?[0-7]?\d(°?\s{0,})[0-5]?\d('?\s{0,})[0-5]?\d(.\d{1,6})?"?\s{0,}|180(°?\s{0,})0?0('?\s{0,})0?0"?)\s{0,}[NnSs]?/gm;
      coordenadaValida = termo.match(regex);
      if (coordenadaValida) {
        regex = /[NnSsWwEeEeOoWw]/gm;
        const verificar_lat_e_long = termo.match(regex);
        if (!verificar_lat_e_long) {
          termo = adicionarLateLong(termo);
        }
        const coordWithSpaces = new Coordinate(termo);
        return coordWithSpaces.toDd();
      }
    } else {
      let termo_tratado = termo.replace(/ /g, "");
      const coord = termo_tratado.split(/(?:\s*,\s*)|\s+/);
      let coord_final =
        coord.length === 1 ? termo.split(/(?:\s*,\s*)|\s+/) : coord;

      coordenadaValida = coord_final.filter(item => {
        if (item !== "") {
          return item;
        }
        return false;
      });
      return coordenadaValida;
    }
  } catch (error) {
    return null;
  }
  return null;
}
export { validarTermoEhCoordenada };

// ([0-8]?\d(°?\s{0,})[0-5]?\d('?\s{0,})[0-5]?\d(.\d{1,6})?"?\s{0,}|90(°?\s{0,})0?0('?\s{0,})0?0"?)\s{0,}[NnSs]?\s{0,}([0-]?[0-7]?\d(°?\s{0,})[0-5]?\d('?\s{0,})[0-5]?\d(.\d{1,6})?"?\s{0,}|180(°?\s{0,})0?0('?\s{0,})0?0"?)\s{0,}[EeOoWw]?
