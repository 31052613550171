import produce from "immer";

const initialStatte = {
  token: null,
  token_api_externa: null,
  authenticated: false,
  usuario: null,
  foto_valida: null,
  cartorio: null,
  cartorios: null,
  especialidades: null,
  permissoes: null,
  expira_em: undefined,
  trocar_cartorio: false,
};

export default function auth(state = initialStatte, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/LOGIN_SUCESSO": {
        draft.authenticated = true;
        draft.usuario = action.payload.usuario;
        draft.cartorio = action.payload.cartorio;
        draft.cartorios = action.payload.cartorios;
        draft.especialidades = action.payload.especialidades;
        draft.permissoes = action.payload.permissoes;
        draft.token = action.payload.token;
        draft.trocar_cartorio = false;
        break;
      }
      case "@auth/LOGOUT_REQUEST": {
        draft.authenticated = false;
        draft.token = null;
        draft.token_api_externa = null;
        draft.foto_valida = null;
        draft.trocar_cartorio = false;
        break;
      }
      case "@auth/ADD_TOKEN": {
        draft.token_api_externa = action.payload.token;
        break;
      }
      case "@auth/ADD_EXPIRACAO": {
        draft.expira_em = action.payload.expira_em;
        break;
      }
      case "@auth/ADD_FOTO": {
        draft.foto_valida = action.payload.foto;
        break;
      }
      case "@auth/ADD_IBGE_LISTA": {
        draft.cartorio.ibges = action.payload.listaIbge;
        draft.cartorio.perimetro = action.payload.perimetro;
        break;
      }
      case "@auth/ALTERAR_CARTORIO": {
        draft.trocar_cartorio = true;
        break;
      }
      case "@municipio/ATT_PERIMETRO": {
        draft.cartorio.perimetro = action.payload.perimetro;
        break;
      }
      default:
    }
  });
}
