import React from "react";
import PropTypes from "prop-types";
import { store } from "~/store";

import PageContent from "~/components/PageContent";

export default function PageWrap({ children, ...rest }) {
  const { authenticated } = store.getState().auth;

  return (
    <>
      {authenticated ? (
        <>
          <PageContent>{children}</PageContent>
        </>
      ) : (
        children
      )}
    </>
  );
}

PageWrap.propTypes = {
  children: PropTypes.element.isRequired
};
