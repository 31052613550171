// import React from "react";
import { all, call, takeLatest, put, delay } from "redux-saga/effects";

import { store } from "~/store/index";
import api from "~/services/api";
import apiVirtualCart from "~/services/apiVirtualCart";
import history from "~/services/history";

import {
  loginSucesso,
  adicionarToken,
  logoutRequest,
  adicionarExpiracao
} from "./actions";

import { addToast } from "~/store/modules/toast/actions";
import { buscarFoto } from "~/store/modules/usuario/actions";

export function* login({ payload }) {
  const { token, continuar } = payload;

  yield put(adicionarToken(token));
  yield delay(50);

  let token_tratado;
  if (token.match("Bearer")) {
    token_tratado = token.slice(7);
  } else {
    token_tratado = token;
  }

  try {
    const responseVirtual = yield call(
      apiVirtualCart,
      "post",
      `autenticacao/externa`,
      {
        token: token_tratado
      }
    );

    let { data, status } = responseVirtual;

    if (status === 200) {
      const response = yield call(api, "post", "autenticar", {
        token: data.dados.token,
        payload: data.dados.payload
      });
      data = response.data;
      status = response.status;

      switch (status) {
        case 200:
          // eslint-disable-next-line no-case-declarations
          const { informacoes } = data.dados;

          yield put(buscarFoto(informacoes.usuario.foto));

          yield put(
            loginSucesso(
              responseVirtual.data.dados.token,
              informacoes.usuario,
              informacoes.cartorio,
              responseVirtual.data.dados.cartorios,
              responseVirtual.data.dados.especialidades,
              responseVirtual.data.dados.permissoes
            )
          );

          if (continuar) {
            window.location = continuar;
            break;
          }

          history.push("/");
          // window.location.reload(true);
          break;

        case 400:
        case 401:
        case 403:
        case 404:
          yield put(
            addToast({
              titulo: "Sua sessão foi expirada",
              tipo: "warning",
              duracao: 10000
            })
          );
          yield put(logoutRequest());
          break;

        default:
          yield put(logoutRequest(true));
          yield put(
            addToast({
              titulo: "Aconteceu algum problema no servidor",
              texto: "Tente novamente mais tarde",
              tipo: "danger"
            })
          );
      }
    } else {
      yield put(adicionarToken(null));
      yield put(logoutRequest());
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
    }
  } catch (error) {
    console.log("Erro ao entrar no sistema:" + error);
    window.location.reload(true);
  }
}

// eslint-disable-next-line require-yield
export function* logout({ payload }) {
  const { sair } = payload;

  console.log("Mosaico: Sessão expirada");

  const LINK_SISTEMA = process.env.REACT_APP_URL_LOGIN_SISTEMA;
  const URL_MOSAICO = process.env.REACT_APP_URL_MOSAICO;

  if (!sair) {
    yield delay(2000);
    window.location.href = `${LINK_SISTEMA}autenticacao`;
    return null;
  }
  yield delay(2000);
  window.location.href = `${LINK_SISTEMA}autenticacao?continuar=${URL_MOSAICO}/login/{token}`;
}

function verificarValidade() {
  const { expira_em } = store.getState().auth;
  let now = new Date().getTime();
  if (!expira_em) {
    store.dispatch(adicionarExpiracao(now));
  }
  var regex = /login/gm;
  if (regex.exec(window.location.href)) {
    store.dispatch(adicionarExpiracao(now));
    return false;
  }

  if (store.getState().auth.expira_em < now - 60000) {
    store.dispatch(adicionarExpiracao(now));
    store.dispatch(logoutRequest());
  } else {
    store.dispatch(adicionarExpiracao(now));
  }
}

// eslint-disable-next-line require-yield
export function* checarValidade() {
  verificarValidade();
  setInterval(() => {
    verificarValidade();
  }, 20000);
}

export function* alterarCartorio({ payload }) {
  const { id } = payload;
  history.push("/");
  const response = yield call(
    apiVirtualCart,
    "get",
    `autenticacao/selecionar/${id}`
  );

  const { data, status } = response;
  switch (status) {
    case 200:
      // eslint-disable-next-line no-case-declarations
      const { token } = data;
      history.push(`/login/${token}`);

      break;

    case 400:
    case 401:
    case 403:
    case 404:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000
        })
      );
      yield put(logoutRequest());
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
}

export default all([
  takeLatest("@auth/LOGIN_REQUEST", login),
  takeLatest("@auth/LOGOUT_REQUEST", logout),
  takeLatest("persist/REHYDRATE", checarValidade),
  takeLatest("@auth/ALTERAR_CARTORIO", alterarCartorio)
]);
