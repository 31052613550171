// import React from "react";
import { all, call, takeLatest, put } from "redux-saga/effects";

// import { store } from "~/store";
import api from "~/services/api";
import history from "~/services/history";

import { addToast } from "~/store/modules/toast/actions";
import { logoutRequest, addListaIbge } from "~/store/modules/auth/actions";

import {
  adicionarMunicipios,
  adicionarListaMunicipios,
  attPerimetro,
  addCircuscricoes,
} from "./actions";

export function* buscarMunicipiosPoligonos({ payload }) {
  const { ibge } = payload;

  const response = yield call(api, "get", `buscar/?ibge=${ibge}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      const { dados } = data;
      const { poligonos } = dados;

      yield put(adicionarMunicipios(poligonos));
    
      if (poligonos.length === 0) {
        yield put(
          addToast({
            titulo: `Seu cartório ainda não possui municípios vinculados`,
            texto: `Entre em contato com o suporte.`,
            tipo: "warning"
          })
        );
      }

      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      break;

    case 404:
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
}

export function* buscarListagemMunicipios({ payload }) {
  const { ibge } = payload;

  const response = yield call(api, "get", `municipios/?ibge=${ibge}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      const { dados } = data;
      let lista = [];
      dados.forEach(item => {
        lista.push({
          label: `${item.cidade} - ${item.ibge}`,
          value: item.id,
          ibge: item.ibge
        });
      });
      yield put(adicionarListaMunicipios(lista));
      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      break;

    case 404:
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
}

export function* adicionarNovosMunicipios({ payload }) {
  const { municipios } = payload;

  const response = yield call(api, "post", `vincular`, { municipios });

  const { status, data } = response;

  switch (status) {
    case 200:
      const { msg, perimetro, ibges } = data.dados;

      if (!perimetro) {
        yield put(addListaIbge(ibges, perimetro));
      }
      yield put(
        addToast({
          titulo: "Solicitação enviada com sucesso",
          texto: msg,
          tipo: "success"
        })
      );
      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          texto: "Na dúvida contate o suporte",
          tipo: "warning"
        })
      );
      break;

    case 404:
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
}

export function* cancelarPerimetro() {
  const response = yield call(api, "put", `perimetro`);

  const { status, data } = response;

  switch (status) {
    case 200:
      const { perimetro } = data.dados;

      if (!perimetro) {
        yield put(attPerimetro(perimetro));
      }
      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          texto: "Na dúvida contate o suporte",
          tipo: "warning"
        })
      );
      break;

    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning"
        })
      );
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
}

export function* buscarCircuscricoes({ payload }) {
  const response = yield call(api, "get", `circuscricoes`);

  const { status, data } = response;

  switch (status) {
    case 200:
      yield put(addCircuscricoes(data.dados));
      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          texto: "Na dúvida contate o suporte",
          tipo: "warning"
        })
      );
      break;

    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning"
        })
      );
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
}
export default all([
  takeLatest("@municipio/BUSCAR_MUNICIPIOS", buscarMunicipiosPoligonos),
  takeLatest("@municipio/BUSCAR_LISTA_MUNICIPIOS", buscarListagemMunicipios),
  takeLatest("@municipio/ADD_NOVOS_MUNICIPIOS", adicionarNovosMunicipios),
  takeLatest("@municipio/PEGAR_CIRCUSCRICOES", buscarCircuscricoes),
  takeLatest("@municipio/CANCELAR_PERIMETRO", cancelarPerimetro),
]);
