export function buscarEstatisticas() {
  return {
    type: "@dash/BUSCAR_ESTATISTICAS",
  };
}

export function addEstatisticas(dados_rural, dados_urbano) {
  return {
    type: "@dash/ADD_ESTATISTICAS",
    payload: { dados_rural, dados_urbano },
  };
}
