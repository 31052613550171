import produce from "immer";

const initialStatte = {
  estatisticas_rural: null,
  estatisticas_urbano: null,
};

export default function dashboard(state = initialStatte, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@dash/ADD_ESTATISTICAS": {
        draft.estatisticas_rural = null;
        draft.estatisticas_urbano = null;
        draft.estatisticas_rural = action.payload.dados_rural;
        draft.estatisticas_urbano = action.payload.dados_urbano;
        break;
      }
      case "@dash/BUSCAR_ESTATISTICAS": {
        draft.estatisticas_rural = null;
        draft.estatisticas_urbano = null;
        break;
      }
      case "@auth/ALTERAR_CARTORIO": {
        draft.estatisticas_rural = null;
        draft.estatisticas_urbano = null;
        break;
      }
      default:
    }
  });
}
