import produce from "immer";

const initialStatte = {
  matricula_verificada: 0,
  matricula_existe: true,
  planta_fake: false,
  arquivo_matricula: null,
  loading_acervo: false
};

export default function acervo(state = initialStatte, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@acervo/MATRICULA_EXISTE": {
        draft.matricula_verificada = action.payload.matricula;
        draft.matricula_existe = action.payload.matricula_existe;
        draft.permissao = action.payload.permissao;
        break;
      }
      case "@acervo/KML_COMPARACAO": {
        draft.planta_fake = action.payload.plantaFake;
        break;
      }
      case "@acervo/ENVIAR_ARQUIVO_MATRICULA": {
        draft.loading_acervo = true;
        break;
      }
      case '@acervo/ATUALIZAR_ARQUIVO_MATRICULA': {
        draft.arquivo_matricula = action.payload.arquivo_matricula;
        draft.loading_acervo = false;
        break;
      }

      default:
    }
  });
}
