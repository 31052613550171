export function enviarArquivos(
  id_imovel,
  matricula_atual,
  kml = null,
  rinex = null
) {
  return {
    type: "@acervo/ENVIAR_ARQUIVOS",
    payload: { id_imovel, kml, rinex, matricula_atual },
  };
}

export function enviarArquivoMatricula(
  arquivo_matricula = null,
) {
  return {
    type: "@acervo/ENVIAR_ARQUIVO_MATRICULA",
    payload: { arquivo_matricula },
  };
}

export function atualizarArquivoMatricula(arquivo_matricula) {
  return {
    type: "@acervo/ATUALIZAR_ARQUIVO_MATRICULA",
    payload: { arquivo_matricula: arquivo_matricula }
  };
}

export function baixarArquivoMatricula(
  id_arquivo_matricula
) {
  return {
    type: "@acervo/BAIXAR_ARQUIVO_MATRICULA",
    payload: { id_arquivo_matricula },
  };
}

export function verificarMatricula(matricula_atual) {
  return {
    type: "@acervo/VERIFICAR_MATRICULA",
    payload: { matricula_atual },
  };
}

export function matriculaExiste(matricula_existe, permissao, matricula) {
  return {
    type: "@acervo/MATRICULA_EXISTE",
    payload: { matricula, matricula_existe, permissao },
  };
}

export function analisarKML(kml = null) {
  return {
    type: "@acervo/ANALISAR_KML",
    payload: { kml },
  };
}

export function enviarElementos(arquivos) {
  return {
    type: "@acervo/ENVIAR_ELEMENTOS",
    payload: { arquivos },
  };
}

export function baixarElemento(local) {
  return {
    type: "@acervo/BAIXAR_ELEMENTOS",
    payload: { local },
  };
}

export function addKMLComparacao(plantaFake) {
  return {
    type: "@acervo/KML_COMPARACAO",
    payload: { plantaFake },
  };
}
