import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_URL_API_VIRTUAL_CART
});
http.interceptors.request.use(async config => {
  const auth = JSON.parse(
    JSON.parse(localStorage.getItem("persist:mosaico")).auth
  );
  if (auth.token_api_externa) {
    config.headers.Authorization = `Bearer ${auth.token_api_externa}`;
  }
  return config;
});

const api = async (method, endpoint, data = {}, params = {}, headers = {}) => {
  const url = endpoint;
  return http
    .request({
      method,
      url,
      data,
      params,
      headers
    })
    .then(response => response)
    .catch(error => error.response);
};

export default api;
