export function addDadosDeComparacao(dados) {
  return {
    type: "@comparar/ADD_DADOS",
    payload: { dados },
  };
}

export function alterarLoading(status) {
  return {
    type: "@comparar/ALTERAR_LOADING",
    payload: { status },
  };
}

export function addCoordenadasTeste(coordenadas) {
  return {
    type: "@comparar/ADD_COORDENADAS_TESTE",
    payload: { coordenadas },
  };
}
