export function buscarMunicipios(ibge) {
  return {
    type: "@municipio/BUSCAR_MUNICIPIOS",
    payload: { ibge },
  };
}

export function adicionarMunicipios(poligonos) {
  return {
    type: "@municipio/ADD_MUNICIPIOS",
    payload: { poligonos },
  };
}

export function buscaListaDeMunicipios(ibge) {
  return {
    type: "@municipio/BUSCAR_LISTA_MUNICIPIOS",
    payload: { ibge },
  };
}

export function adicionarListaMunicipios(lista) {
  return {
    type: "@municipio/ADD_LISTA_MUNICIPIOS",
    payload: { lista },
  };
}

export function adicionarNovosMunicipios(municipios) {
  return {
    type: "@municipio/ADD_NOVOS_MUNICIPIOS",
    payload: { municipios },
  };
}

export function pegarCircuscricoes() {
  return {
    type: "@municipio/PEGAR_CIRCUSCRICOES",
  };
}

export function addCircuscricoes(dados) {
  return {
    type: "@municipio/ADD_CIRCUSCRICOES",
    payload: { dados },
  };
}

export function pararLoading() {
  return {
    type: "@municipios/PARAR_LOADING",
  };
}

export function cancelarPerimetro() {
  return {
    type: "@municipio/CANCELAR_PERIMETRO",
  };
}

export function attPerimetro(perimetro) {
  return {
    type: "@municipio/ATT_PERIMETRO",
    payload: { perimetro },
  };
}
