import { all } from "redux-saga/effects";
import auth from "./auth/sagas";
import mosaico from "./mosaico/sagas";
import virtualCart from "./virtualCart/sagas";
import usuario from "./usuario/sagas";
import municipio from "./municipio/sagas";
import dashboard from "./dashboard/sagas";
import acervo from "./acervo/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    mosaico,
    virtualCart,
    usuario,
    municipio,
    dashboard,
    acervo
  ]);
}
