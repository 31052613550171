import produce from "immer";

const initialStatte = {
  poligonos: [],
  copia_poligonos: [],
  centro: null,
  zoom: 11,
  planta: {
    propriedades: {},
    coordenadas: [],
    detentores: [],
  },
  documentos: undefined,
  atual: 1,
  total: 1,
  ultima: 1,
  limite: 15,
  busca: false,
  incompleto: false,
  busca_coord: false,
  busca_matricula: false,
  termo: "",
  pessoaNome: undefined,
  imovelBusca: undefined,
  filtroRetificados: null,
  filtroDaLista: null,
  atualizado_em: null,
  loading: false,
  loading_situacao: false,
  loading_filtro: false,
  loading_mapa: false,
  marcosImovel: [],
  novo_imovel_tem_sobreposicao: false,
  conflitos: [],
  ocultar_coordenada_busca: false,
  imoveis_filtrados: false,
};

export default function mosaico(state = initialStatte, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@mosaico/ATUALIZAR_POLIGONOS": {
        draft.copia_poligonos = action.payload.poligonos;
        draft.poligonos = action.payload.poligonos;
        draft.busca = action.payload.busca;
        draft.incompleto = action.payload.incompleto;
        draft.atual = action.payload.atual;
        draft.total = action.payload.total;
        draft.ultima = action.payload.ultima;
        draft.zoom = action.payload.zoom;
        draft.atualizado_em = action.payload.atualizado_em;
        draft.conflitos = action.payload.conflitos;
        break;
      }
      case "@mosaico/BUSCAR_POLIGONOS": {
        draft.limite = action.payload.limite;
        break;
      }
      case "@mosaico/ADICIONAR_PLANTA": {
        draft.planta = action.payload.planta;
        draft.centro = action.payload.centro;
        break;
      }
      case "@mosaico/LIMPAR_CENTRO": {
        draft.centro = null;
        break;
      }
      case "@mosaico/FILTRAR_MOSAICOS": {
        draft.busca = true;
        draft.termo = action.payload.termo;
        draft.ocultar_coordenada_busca = false;
        break;
      }
      case "@mosaico/LIMPAR_BUSCA": {
        draft.busca = false;
        draft.incompleto = false;
        draft.busca_coord = false;
        draft.busca_matricula = false;
        draft.ocultar_coordenada_busca = action.payload.ocultar;
        draft.termo = "";
        draft.zoom = 11;
        break;
      }
      case "@mosaico/ADICIONAR_DOCUMENTOS": {
        draft.documentos = action.payload.documentos;
        break;
      }
      case "@mosaico/LIMPAR_DOCUMENTOS": {
        draft.documentos = undefined;
        break;
      }
      case "@mosaico/ADICIONAR_PESSOA_NOME": {
        draft.pessoaNome = action.payload.nome;
        break;
      }
      case "@mosaico/ADD_IMOVEL_BUSCADO": {
        draft.imovelBusca = action.payload.imovel;
        break;
      }
      case "@mosaico/ESTADO_LISTAGEM": {
        draft.busca_coord = action.payload.busca_coord;
        draft.busca_matricula = action.payload.busca_matricula;
        break;
      }
      case "@mosaico/RETIFICADOS": {
        draft.filtroRetificados = action.payload.tipo;
        break;
      }
      case "@mosaico/LISTAGEM": {
        draft.filtroDaLista = action.payload.tipo;
        break;
      }
      case "@mosaico/STATUS_LOADING": {
        draft.loading = action.payload.loading;
        break;
      }
      case "@mosaico/STATUS_LOADING_FILTRO": {
        draft.loading_filtro = action.payload.loading;
        break;
      }
      case "@mosaico/STATUS_LOADING_MAPA": {
        draft.loading_mapa = action.payload.loading;
        break;
      }
      case "@auth/ALTERAR_CARTORIO": {
        draft.poligonos = [];
        draft.copia_poligonos = [];
        draft.termo = "";
        draft.filtroRetificados = null;
        break;
      }
      case "@mosaico/ADD_DETENTOR": {
        draft.planta.detentores = action.payload.detentores;
        break;
      }
      case "@mosaico/ADD_MARCO": {
        draft.marcosImovel = [...draft.marcosImovel, action.payload.posicao];
        break;
      }
      case "@mosaico/LIMPAR_MARCO": {
        draft.marcosImovel = [];
        break;
      }

      case "@mosaico/IMOVEL_SOBREPOSICAO": {
        draft.novo_imovel_tem_sobreposicao =
          action.payload.novo_imovel_tem_sobreposicao;
        break;
      }
      case "@mosaico/BUSCAR_SITUACAO": {
        draft.loading_situacao = true;
        break;
      }
      case "@mosaico/NOVA_SITUACAO_IMOVEL": {
        draft.loading_situacao = false;
        break;
      }
      case "@mosaico/ADD_IMOVEIS_FILTRADOS": {
        draft.imoveis_filtrados = action.payload.imoveis;
        break;
      }
      default:
    }
  });
}
