export const modulosAdmin = [
  { id: 10, url: null, nome: "clientes", titulo: "Clientes" },
  { id: 11, url: null, nome: "chat", titulo: "VirtualChat" },
  {
    id: 13,
    url: null,
    nome: "controle_processos",
    titulo: "Controle de Processos"
  },
  { id: 1, url: null, nome: "financeiro", titulo: "Financeiro" },
  { id: 3, url: null, nome: "acervo", titulo: "Acervo" },
  { id: 4, url: null, nome: "mosaico", titulo: "Mosaico" }
];
