import React from "react";
import PropTypes from "prop-types";
import {
  CVPopUp,
  CVPopUpMatricula,
} from "@nato-digital/nato-components/build/index";
import PageNavBar from "~/components/PageNavBar";
import PageToast from "~/components/PageToast";
import PageMenu from "~/components/PageMenu";
// import "./style.scss";
import { store } from "~/store";

export default function PageContent({ children }) {
  const { authenticated } = store.getState().auth;

  return (
    <div className="hk-wrapper hk-vertical-nav ">
      {authenticated && <PageMenu />}
      {authenticated && <PageNavBar />}
      <CVPopUp />
      <CVPopUpMatricula />
      <PageToast />
      <div className="hk-pg-wrapper pb-0 px-0">
        <div
          className={
            children.props.path === "/mapa" ||
            children.props.path === "/detalhe/:id" ||
            children.props.path === "/comparar"
              ? "container-fluid"
              : "container"
          }
        >
          <div className="row">
            <div className="col-xl-12 pa-0">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

PageContent.propTypes = {
  children: PropTypes.element,
};
