export function addToast(dados) {
  return {
    type: "@toast/ADD_TOAST",
    payload: dados
  };
}

export function removeToast(id) {
  return {
    type: "@toast/REMOVE_TOAST",
    payload: id
  };
}
