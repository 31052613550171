import produce from "immer";

const initialStatte = {
  dados: {},
  coordenadas_teste: false,
  loading: false,
};

export default function comparar(state = initialStatte, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@acervo/ANALISAR_KML": {
        draft.loading = true;
        draft.dados = {};
        break;
      }
      case "@comparar/ADD_DADOS": {
        draft.dados = action.payload.dados;
        draft.coordenadas_teste = action.payload.dados.coordenadas;
        draft.loading = false;
        break;
      }
      case "@comparar/ADD_COORDENADAS_TESTE": {
        draft.coordenadas_teste = action.payload.coordenadas;
        draft.loading = false;
        break;
      }
      case "@comparar/ALTERAR_LOADING": {
        draft.loading = action.payload.status;
        break;
      }
      default:
        return state;
    }
  });
}
