// import React from "react";
import { all, call, takeLatest, put, delay } from "redux-saga/effects";

// import { store } from "~/store/index";
import api from "~/services/api";
// import history from "~/services/history";

import { addEstatisticas } from "./actions";
import { logoutRequest } from "~/store/modules/auth/actions";

import { addToast } from "~/store/modules/toast/actions";

export function* buscar() {
  yield delay(50);
  const response = yield call(api, "get", `estatisticas`);

  let { data, status } = response;

  switch (status) {
    case 200:
      const { dados } = data;

      yield put(addEstatisticas(dados.dados_rurais, dados.dados_urbanos));

      break;
    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      break;

    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export default all([takeLatest("@dash/BUSCAR_ESTATISTICAS", buscar)]);
