// TODO refatorar breadcrumbs
const routes = [
  {
    title: "Mapa",
    component: "Imoveis/Mapa",
    path: "/mapa",
    auth: true,
    admin: false,
    exact: true,
  },
  {
    title: "Lista",
    component: "Imoveis/Lista",
    path: "/lista",
    auth: true,
    admin: false,
    exact: true,
  },
  {
    title: "Login",
    component: "Auth/Login",
    path: "/login/:token",
    auth: false,
    admin: false,
    exact: true,
  },
  {
    title: "Erro",
    component: "Auth/Erro",
    path: "/erro",
    auth: false,
    admin: false,
    exact: true,
  },
  {
    title: "Logout",
    component: "Auth/Logout",
    path: "/logout",
    auth: false,
    admin: false,
    exact: true,
  },
  {
    title: "Detalhe",
    component: "Imoveis/Detalhe",
    path: "/detalhe/:id",
    auth: true,
    admin: false,
    exact: true,
  },
  {
    title: "CriarAtualizar",
    component: "Imoveis/CriarAtualizar",
    path: "/formImovel/:id?",
    auth: true,
    admin: false,
    exact: true,
  },
  {
    title: "Comparar KML",
    component: "Imoveis/Comparar",
    path: "/comparar",
    auth: true,
    admin: false,
    exact: true,
  },
  {
    title: "Config",
    component: "Config",
    path: "/config",
    auth: true,
    admin: false,
    exact: true,
  },
  {
    title: "Dashboard",
    component: "Dashboard/Inicio",
    path: "/",
    auth: true,
    admin: true,
    exact: false,
  },
  {
    title: "NotFound",
    component: "Auth/NaoEncontrado",
    path: "*",
    auth: false,
    admin: false,
    exact: false,
  },
];

export default routes;
