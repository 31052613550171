export function loginRequest(token, continuar) {
  return {
    type: "@auth/LOGIN_REQUEST",
    payload: { token, continuar }
  };
}

export function loginSucesso(
  token,
  usuario,
  cartorio,
  cartorios,
  especialidades,
  permissoes
) {
  return {
    type: "@auth/LOGIN_SUCESSO",
    payload: {
      token,
      usuario,
      cartorio,
      cartorios,
      especialidades,
      permissoes
    }
  };
}

export function logoutRequest(sair = false) {
  return {
    type: "@auth/LOGOUT_REQUEST",
    payload: { sair }
  };
}

export function adicionarToken(token) {
  return {
    type: "@auth/ADD_TOKEN",
    payload: { token }
  };
}

export function adicionarExpiracao(expira_em) {
  return {
    type: "@auth/ADD_EXPIRACAO",
    payload: { expira_em }
  };
}

export function adicionarFoto(foto) {
  return {
    type: "@auth/ADD_FOTO",
    payload: { foto }
  };
}

export function alterarCartorio(id) {
  return {
    type: "@auth/ALTERAR_CARTORIO",
    payload: { id }
  };
}

export function addListaIbge(listaIbge, perimetro = true) {
  return {
    type: "@auth/ADD_IBGE_LISTA",
    payload: { listaIbge, perimetro }
  };
}
