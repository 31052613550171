// import axios from "axios";
import { setup } from "axios-cache-adapter";

const http = setup({
  baseURL: process.env.REACT_APP_API_URL,
  cache: {
    maxAge: 0 * 60 * 1000,
  },
});

http.interceptors.request.use(async (config) => {
  const auth = JSON.parse(
    JSON.parse(localStorage.getItem("persist:mosaico")).auth
  );
  if (auth.token) {
    config.headers.Authorization = `Bearer ${auth.token}`;
  }
  return config;
});

const api = async (method, endpoint, data = {}, params = {}, headers = {}) => {
  const url = endpoint;
  const regex = /^(mapas)/gm;

  if (url.match(regex) && method === "get") {
    const uma_hora = 60 * 60 * 1000;
    return http
      .get(url, {
        cache: {
          maxAge: uma_hora,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => error.response);
  }

  return http
    .request({
      method,
      url,
      data,
      params,
      headers,
    })
    .then(async (response) => response)
    .catch((error) => error.response);
};

export default api;
