export function buscarFoto(foto) {
  return {
    type: "@usuario/BUSCAR_FOTO",
    payload: { foto },
  };
}

export function vincularDetentor(imovel_id, cpfcnpj, detentor) {
  return {
    type: "@usuario/VINCULAR_DETENTOR",
    payload: { imovel_id, cpfcnpj, detentor },
  };
}

export function removerVinculo(imovel_id, detentor_cpfcnpj) {
  return {
    type: "@usuario/DESVINCULAR_DETENTOR",
    payload: { imovel_id, detentor_cpfcnpj },
  };
}
