import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./store";
import Routes from "./routes";
import history from "./services/history";
import "~/styles/index.js";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <Routes />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
