// import React from "react";
import { all, takeLatest } from "redux-saga/effects";

import { store } from "~/store/index";
// import history from "~/services/history";

// eslint-disable-next-line require-yield
export function* moduloExterno({ payload }) {
  const { modulo } = payload;

  const LINK_SISTEMA = process.env.REACT_APP_URL_LOGIN_SISTEMA;
  const { token_api_externa } = store.getState().auth;


  var token_tratado;
  if (token_api_externa.match("Bearer")) {
    token_tratado = token_api_externa.slice(7);
  } else {
    token_tratado = token_api_externa;
  }
  var url = `${LINK_SISTEMA}jwt/${token_tratado}?continuar=${LINK_SISTEMA}${modulo}`;

  window.location.href = url;
}

export default all([takeLatest("@externo/VIRTUAL_MODULO", moduloExterno)]);
