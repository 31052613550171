import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import { store } from "~/store";
import PageWrap from "~/components/PageWrap";
import { logoutRequest } from "~/store/modules/auth/actions";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  let { authenticated } = store.getState().auth;
  const dispatch = useDispatch();

  if (!authenticated && isPrivate) {
    dispatch(logoutRequest(true));
    return (
      <Spinner
        style={{ position: "fixed", zIndex: "1031", top: "50%", left: "50%" }}
        color="secondary"
      />
    );
  }

  return (
    <PageWrap {...rest}>
      <Route {...rest} component={Component} />
    </PageWrap>
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
